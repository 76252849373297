import * as React from 'react';
import { graphql } from 'gatsby';
import SeriesPage from '../../templates/SeriesPage';

function Series(props) {
  const { series } = props.data;
  return <SeriesPage series={series} />;
}

export default Series;

export const query = graphql`
  query($id: String!) {
    series: sanitySeries(id: { eq: $id }) {
      title
      subtitle
      id
      slug {
        current
      }
      image {
        asset {
          fluid(maxWidth: 900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      sermons {
        title
        passage
        id
        series {
          title
          image {
            asset {
              fixed(width: 250, height: 156) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        slug {
          current
        }
        preachedDate
        speaker {
          name
          image {
            asset {
              fixed(width: 250, height: 156) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }
    }
  }
`;

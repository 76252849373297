/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import Img from 'gatsby-image';
import { Box, Heading, jsx, AspectRatio, Button } from 'theme-ui';
import { SEO } from '../utils';
import SermonSeriesCard from '../components/SermonSeriesCard';
import SermonButton from '../components/SermonButton';
import ContentContainer from '../components/content-container';

export const SermonLabel = ({ children }) => (
  <span sx={{ fontFamily: 'heading', fontSize: 0, color: 'primary' }}>
    {children}
  </span>
);

export const Page = ({ series }) => {
  function compare(a, b) {
    const sermonA = a.preachedDate;
    const sermonB = b.preachedDate;

    let comparison = 0;
    if (sermonA > sermonB) {
      comparison = 1;
    } else if (sermonA < sermonB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  const sortedSermons = series.sermons.sort(compare);

  return (
    <>
      <SEO title={series.title} />
      <Box>
        <ContentContainer>
          <Img
            fluid={series.image.asset.fluid}
            sx={{
              maxHeight: ['600px'],
            }}
          />

          <Heading
            as="h1"
            sx={{
              pt: 3,
              fontFamily: 'fancyFont',
              fontSize: [5, '3.3rem'],
              textAlign: 'center',
            }}
          >
            {series.title}
          </Heading>
          <Heading
            as="h2"
            sx={{ mb: 2, pb: 2, color: 'meddark', textAlign: 'center' }}
          >
            {series.subtitle}
          </Heading>
          <SermonButton to="/sermons" text="Full archive" />
          <SermonButton to="/sermon-series/" text="All Sermon Series" />

          {sortedSermons.map(sermon => (
            <SermonSeriesCard key={sermon.id} sermon={sermon} />
          ))}
        </ContentContainer>
      </Box>
    </>
  );
};

export default Page;
